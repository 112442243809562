@tailwind base;
@tailwind components;
@tailwind utilities;



@layer base {
  @font-face {
    font-family: AFonts;
    font-weight: 200;
    src: url(assets/fonts/ALight.woff2) format("woff2");
  }


  @font-face {
    font-family: AFonts;
    font-weight: 500;
    src: url(assets/fonts/ARegular.woff2) format("woff2");
  }

  
  @font-face {
    font-family: AFonts;
    font-weight: 700;
    src: url(assets/fonts/ASemiBold.woff2) format("woff2");
  }

    
  @font-face {
    font-family: AFonts;
    font-weight: 900;
    src: url(assets/fonts/ABold.woff2) format("woff2");
  }


}


body {
  margin: 0;
  font-family:AFonts, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.borderedText:hover{
  text-shadow: none;
  color:black
}

.borderedText{
  color:white;  
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  transition: color 500ms ease 100ms

}


.Home, .Contact {
  height: 100vh;
  background-color: #161616;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%,0%);
}

.Home > div , .Contact > div {
  /* padding-top: 300px !important; */
  color: #ff7f82;
  font-size: 7rem;
}

.Contact > div {
  color: #ffa260;
}

.button {
  background: none;
  border: 2px solid #a972cb;
  font: inherit;
  line-height: 1;
  padding: .3rem 3rem;
  text-decoration: none;
  font-size: 30px;
  color: #a972cb;
  font-family: 'Roboto Mono', monospace;
  text-transform: uppercase;
  outline: none;
  transition: all .3s;
}

.button:hover,
.button:focus {
  box-shadow: inset 0 0 0 2em rgb(169, 114, 203,.2);
}

.load-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 10;
  pointer-events: none;
}

.load-screen {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #19bc8b;
  width: 0%;
  height: 100%;
}

.load-screen1 {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #efeef2;
  width: 100%;
  height: 100vh;
}

.Headd {
  opacity: 0;
  pointer-events: none;
}